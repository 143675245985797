import "@babel/polyfill";
require("element-closest-polyfill");
require("array-from-polyfill");
require("object-assign");
import { Loading } from "./module/common/loading";
import { SetGnav } from "./module/common/set-gnav";
import { CommonScroll } from "./module/common/common-scroll";
import { Placeholder } from "./module/common/placeholder";
import { SetWidth } from "./module/common/set-width";
import { SmoothScroll } from "./module/common/smooth-scroll";
import { CommonAccordion } from "./module/common/common-accordion";
import { CommonTab } from "./module/common/common-tab";
import { ItemSlider } from "./module/common/item-slider";
import { HomeFacilitySlider } from "./module/common/home-facility-slider";
import { CommonModal } from "./module/common/common-modal";
import { Scrollbar } from "./module/common/scrollbar";
document.addEventListener("DOMContentLoaded", () => {
    new Loading();
    new SetGnav();
    new CommonScroll("js-inview");
    Placeholder.init();
    SetWidth.init();
    SmoothScroll.init();
    CommonAccordion.init();
    CommonTab.init();
    HomeFacilitySlider.init();
    ItemSlider.init();
    new CommonModal();
    Scrollbar.init();
}, false);
