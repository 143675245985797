import imagesLoaded from 'imagesloaded';
const objectFitImages = require('object-fit-images');
export class Loading {
    constructor() {
        this.loadedFlag = false;
        this.isRunning = false;
        this.root = document.querySelector('html');
        this.loadingElm = document.getElementById('js-loading');
        this.loadedImg = 0;
        this.imgLength = imagesLoaded('body', { background: true }).images.length;
        this.progressElm = document.getElementById('js-progress');
        this.progressCurrent = 0;
        this.timer = 0;
        this.ofiTarget = document.getElementsByClassName('js-ofi');
        this.videoSrc = null;
        this.videoSrcPc = null;
        this.videoElm = document.getElementById('js-home-kv-video');
        if (this.videoElm !== null) {
            this.videoSrc = this.videoElm.getAttribute('data-src');
            this.videoSrcPc = this.videoElm.getAttribute('data-src-pc');
        }
        this.start();
    }
    start() {
        if (this.loadingElm) {
            this.loadVideo();
            imagesLoaded(this.loadingElm, () => {
                this.timer = window.setInterval(this.monitorProgress.bind(this), 1000 / 50);
                imagesLoaded('body', { background: true }).on('progress', () => {
                    this.loadedImg++;
                });
            });
        }
    }
    monitorProgress() {
        const progressPer = (this.loadedImg / this.imgLength) * 100;
        this.progressCurrent += (progressPer - this.progressCurrent) * 0.1;
        if (this.progressElm) {
            this.progressElm.style.width = `${this.progressCurrent}%`;
        }
        if (this.progressCurrent >= 100) {
            this.clearLoading();
            if (Loading.mediaQuery != null) {
                Loading.mediaQuery.addEventListener('change', this.handleVideo.bind(this), false);
            }
        }
        if (this.progressCurrent > 99.9) {
            this.progressCurrent = 100 + 10;
        }
    }
    clearLoading() {
        clearInterval(this.timer);
        if (this.ofiTarget.length) {
            objectFitImages(this.ofiTarget);
        }
        if (this.videoElm !== null && this.videoElm instanceof HTMLVideoElement) {
            if (this.videoElm.readyState === 4) {
                if (this.root) {
                    this.root.classList.add('is-loaded');
                }
                if (this.loadingElm) {
                    this.loadingElm.classList.add('is-hidden');
                }
                this.videoElm.play();
            }
            else {
                this.videoElm.addEventListener('canplaythrough', () => {
                    if (this.root) {
                        this.root.classList.add('is-loaded');
                    }
                    if (this.loadingElm) {
                        this.loadingElm.classList.add('is-hidden');
                    }
                    if (this.videoElm == null || !(this.videoElm instanceof HTMLVideoElement)) {
                        return;
                    }
                    this.videoElm.play();
                });
            }
        }
        else {
            if (this.root) {
                this.root.classList.add('is-loaded');
            }
            if (this.loadingElm) {
                this.loadingElm.classList.add('is-hidden');
            }
        }
        this.loadedFlag = true;
    }
    loadVideo() {
        if (this.videoElm !== null) {
            if (!(this.videoElm instanceof HTMLVideoElement)) {
                return;
            }
            if (Loading.mediaQuery != null && Loading.mediaQuery.matches) {
                if (this.videoSrcPc !== null) {
                    this.videoElm.src = this.videoSrcPc;
                    this.videoElm.load();
                }
            }
            else {
                if (this.videoSrc !== null) {
                    this.videoElm.src = this.videoSrc;
                    this.videoElm.load();
                }
            }
        }
    }
    handleVideo() {
        if (this.videoElm !== null) {
            if (!(this.videoElm instanceof HTMLVideoElement)) {
                return;
            }
            if (Loading.mediaQuery != null && Loading.mediaQuery.matches) {
                if (this.videoSrcPc !== null) {
                    this.videoElm.src = this.videoSrcPc;
                    this.videoElm.load();
                    this.videoElm.play();
                }
            }
            else {
                if (this.videoSrc !== null) {
                    this.videoElm.src = this.videoSrc;
                    this.videoElm.load();
                    this.videoElm.play();
                }
            }
        }
    }
}
Loading.mediaQuery = window.matchMedia('screen and (min-width: 768px)');
