const inView = require('in-view');
export class CommonScroll {
    constructor(selectorClassName) {
        this.isRunning = false;
        this.selector = `.${selectorClassName}`;
        this.bindEvent();
    }
    bindEvent() {
        window.addEventListener('scroll', () => {
            if (!this.isRunning) {
                window.requestAnimationFrame(() => {
                    this.isRunning = false;
                    CommonScroll.scrollPos = window.pageYOffset;
                    this.checkSiteScrolled();
                    this.toggleHeaderState();
                });
                this.isRunning = true;
            }
            inView.offset({
                bottom: 60
            });
            inView(this.selector).on('enter', (target) => {
                target.classList.add('is-inview');
            });
        }, false);
    }
    checkSiteScrolled() {
        if (CommonScroll.scrollPos > CommonScroll.offset) {
            if (CommonScroll.root !== null) {
                if (CommonScroll.root.classList.contains('site-scroll-up')) {
                    CommonScroll.root.classList.remove('site-scroll-up');
                    CommonScroll.root.classList.add('site-scroll-down');
                }
                else if (!CommonScroll.root.classList.contains('site-scroll-down')) {
                    CommonScroll.root.classList.add('site-scroll-down');
                }
            }
        }
        else {
            if (CommonScroll.root !== null) {
                if (CommonScroll.root.classList.contains('site-scroll-down')) {
                    CommonScroll.root.classList.remove('site-scroll-down');
                    CommonScroll.root.classList.add('site-scroll-up');
                }
                else if (!CommonScroll.root.classList.contains('site-scroll-up')) {
                    CommonScroll.root.classList.add('site-scroll-up');
                }
            }
        }
        CommonScroll.offset = CommonScroll.scrollPos;
    }
    toggleHeaderState() {
        if (CommonScroll.headerTrg !== null) {
            CommonScroll.headerTrgClientRect = CommonScroll.headerTrg.getBoundingClientRect().top;
            CommonScroll.headerTrgPos = CommonScroll.scrollPos + CommonScroll.headerTrgClientRect;
        }
        else {
            CommonScroll.headerTrgPos = 0;
        }
        if (CommonScroll.root !== null && CommonScroll.header !== null) {
            if (CommonScroll.scrollPos > CommonScroll.headerTrgPos) {
                if (!CommonScroll.header.classList.contains('is-fixed')) {
                    CommonScroll.header.classList.add('is-fixed');
                    setTimeout(function () {
                        if (CommonScroll.root !== null) {
                            CommonScroll.root.classList.add('header-is-fixed');
                        }
                    }, 500);
                }
            }
            else {
                if (CommonScroll.header.classList.contains('is-fixed')) {
                    CommonScroll.header.classList.remove('is-fixed');
                    CommonScroll.root.classList.remove('header-is-fixed');
                }
            }
        }
    }
    static init(className) {
        const target = document.getElementsByClassName(className);
        if (target.length) {
            new CommonScroll(className);
        }
    }
}
CommonScroll.root = document.querySelector('html');
CommonScroll.header = document.getElementById('js-header');
CommonScroll.headerTrg = document.getElementById('js-header-trg');
CommonScroll.headerTrgPos = 0;
CommonScroll.headerTrgClientRect = 0;
CommonScroll.scrollPos = 0;
CommonScroll.offset = 0;
