import SimpleBar from 'simplebar';
export class Scrollbar {
    constructor(target) {
        this.target = target;
        this.process();
        this.bindEvent();
    }
    process() {
        new SimpleBar(this.target, {
            autoHide: false
        });
    }
    bindEvent() {
        this.target.addEventListener('wheel', (e) => {
            const direction = e.detail ? e.detail * -100 : e.deltaY;
            const scrollElm = this.target.getElementsByClassName('simplebar-content-wrapper')[0];
            if (scrollElm == null) {
                return;
            }
            let position = scrollElm.scrollLeft;
            position += direction > 0 ? -100 : 100;
            scrollElm.scrollLeft = position;
            e.preventDefault();
        }, false);
    }
    static init() {
        const target = document.getElementsByClassName('js-scrollbar');
        if (target.length) {
            for (let i = 0; i < target.length; i++) {
                if (!(target[i] instanceof HTMLElement)) {
                    continue;
                }
                const thisTarget = target[i];
                new Scrollbar(thisTarget);
            }
        }
    }
}
