const Swiper = require('./../vendor/swiper.min');
export class ItemSlider {
    constructor(targetIdName) {
        this.targetSelector = targetIdName;
        this.initSwiper();
    }
    initSwiper() {
        const initSlider = new Swiper(`#${this.targetSelector}`, this.getOption());
    }
    getOption() {
        const option = {
            loop: true,
            loopAdditionalSlides: 1,
            speed: 800,
            centeredSlides: true,
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.s-item-slider__next',
                prevEl: '.s-item-slider__prev',
            },
            autoplay: {
                delay: 3000,
            },
            breakpoints: {
                767: {
                    slidesPerView: 'auto',
                }
            },
        };
        return option;
    }
    static init() {
        const targetIdName = 'js-item-slider';
        const target = document.getElementById(targetIdName);
        if (target === null || !(target instanceof HTMLElement)) {
            return;
        }
        new ItemSlider(targetIdName);
    }
}
